import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiUrlService } from './api-url.service';
import { CadServiceLineDetail } from './cadServiceLineDetail';
import { DataService } from './data.service';
import { UserShared } from './user.shared';
import * as _ from 'lodash';
import { HostingSearchParams } from './hostingSearchParams';


@Injectable({
    providedIn: 'root'
})

export class Cstmgmt {
  functionGroups = [];
  hostingSearchParams: HostingSearchParams;

    constructor(private dataService: DataService, private apiUrlService: ApiUrlService) {
    }

    searchOpmUsers(data): Observable<UserShared[]> {
        return this.dataService.getTyped<any, UserShared[]>(data, this.apiUrlService.searchOpmUsers);
    }

    getSelectedFunction(functionGroup) {
      const selectedFunctionGroup = _.find(this.functionGroups, item => item.customerId.toString() === functionGroup);
      return selectedFunctionGroup ? selectedFunctionGroup.description : null;
    }

    getCustomers(): Observable<any> {
      return this.dataService.getNoParams<any>(this.apiUrlService.customers);
    }

    getAcc(hostingSearchParams: HostingSearchParams): Observable<any> {
      console.log(hostingSearchParams);
      return this.dataService.post<any>(hostingSearchParams, this.apiUrlService.getAcc);
    }

    createView(data: any): any {
      return this.dataService.post<any>(data, this.apiUrlService.createView);
    }

}
