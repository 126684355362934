<mat-card>
    <div class="row">
        <div class="col-sm-12">
            <form [formGroup]="AddCmViewForm">
                <div class="row top-spacing-10">
                    <div class="col-sm-12">
                        <div class="form-group">
                            <label class="input-label">Cloudability View Name<span class="asterik">*</span></label>
                            <!-- [disabled]="summaryInformation.isManage || !summaryInformation.applicationInformation.memberFirmCode" -->
                            <select class="form-control" formControlName="cloudViewName" (change)="cvnChange()">
                            <option value="">--Select a Cloudability View Name--</option>
                            <option *ngFor="let cvn of cloudViewNames" [value]="cvn.id">
                                {{cvn.description}}
                            </option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="row top-spacing-10">
                    <div class="col-md-6 col-lg-6 col-xs-6">
                        <div class="form-group">
                            <label class="input-label">Add Users<span class="asterik">*</span></label>
                            <i class="fa fa-info-circle fa-help-icon-custom" title=""></i>
                            <br>
                            <mat-chip-list *ngIf="">
                                <mat-chip *ngFor="let manager of AssignedUsers">
                                    {{manager}}&nbsp;<i class="fa fa-close close-icon" title="Remove"
                                        (click)="removeSelectedAssignUsers(manager)"></i>
                                </mat-chip>
                            </mat-chip-list>
                            <mat-form-field class="fromInputWidth">
                                <input type="text" matInput formControlName="assignUsers" class="mat-select-container"
                                    [matAutocomplete]="assignUsers" name="assignUsers">
                                <mat-autocomplete #assignUsers="matAutocomplete"
                                    (optionSelected)="assignUsersOptionSelected()" [displayWith]="displayOpmUserFn"
                                    (closed)="handleAssignUsersClosedEvent($event)" autoActiveFirstOption>
                                    <mat-option [value]="" *ngIf="filteredOptionsAssignUsers.length === 0">No results
                                        found
                                    </mat-option>
                                    <mat-option *ngFor="let option of filteredOptionsAssignUsers" [value]="option">
                                        <div *ngIf="option">{{option.lastName}}, {{option.firstName}} -
                                            {{option.emailAddress}}</div>
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                            <br>
                        </div>
                    </div>
                </div>
                <div class="row top-spacing-10">
                    <div class="col-md-12 col-lg-12 col-xs-12">
                        <div class="action-buttons-container action-buttons-root">
                            <button type="button" id="update"
                                [disabled]="AddCmViewForm.invalid"
                                class="btn ssp-primary-btn float-right" (click)="save()">
                                <i class="fa fa-floppy-o" aria-hidden="true"></i> Save
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</mat-card>