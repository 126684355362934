/*
List of all web api urls and handles building of appropriate url.
*/
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiUrlService {
  constructor() { }

  get searchOpmUsers() {
    return environment.apiUrl + '/cloudability/opmuserstemp';
  }

  get getCurrentUser() {
    return environment.apiUrl + '/currentUserProfile';
  }

  get customers() {
    return environment.apiUrl + '/lookup/customers';
  }

  get getAcc() {
    return environment.apiUrl + '/cloudability/searchCloudAccounts?';
  }

  get createView() {
    return environment.apiUrl + '/cloudability/createView';
  }


}
