export class CloudabilityCreateViewRequest {
    viewName: string;
    cloudProviderId: number;
    memberFirmCode: string;
    customerId: number;
    users: string[];
    cloudAccounts: CloudAccountDetail[];
}

export class CloudAccountDetail {
    cloudAccountId: string;
    bundleId: number;
    bundleEnvironmentId: string;
}
