import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Cstmgmt } from '../shared/costmgmt.service';
import { CostMgmtUserInformation } from '../shared/costMgmtUserInformation';
import * as _ from 'lodash';
import { FunctionGroup } from '../shared/functiongroup';
import { MessageService } from '../shared/message.service';


@Component({
  selector: 'app-cost-management-home',
  templateUrl: './cost-management-home.component.html',
  styleUrls: ['./cost-management-home.component.less']
})
export class CostManagementHomeComponent implements OnInit {
  costMgmtUserInfo = new CostMgmtUserInformation();


  isShowAccessError = false;
  commingSoon = true;
  CreateCmcForm: FormGroup;
  costMgmtOptions: FormControl = new FormControl('', Validators.compose([Validators.required]));
  cloudOptions: FormControl = new FormControl('', Validators.compose([Validators.required]));
  functionGroup = new FormControl('');
  functionGroups = [];


  constructor(private ref: ChangeDetectorRef,
              private fb: FormBuilder,
              private cstmgmt: Cstmgmt,
              private toastr: ToastrService,
              private messageService: MessageService) {
    this.messageService.onResetCostManagement((isReset: boolean) => {
      if (isReset === true) {
        this.reset();
      }
    });
  }

  ngOnInit(): void {
    this.CreateCmcForm = this.fb.group({
      costMgmtOptions: this.costMgmtOptions,
      cloudOptions: this.cloudOptions,
      functionGroup: this.functionGroup,
    });
    this.functionGroups = [];
    this.loadCustomers();
  }

  costMgmtOptionsChange() {
    this.costMgmtUserInfo.costMgmtOptions = this.costMgmtOptions.value;
    if (this.costMgmtOptions.value !== '1') {
      this.cloudOptions.setValue(null);
      this.cloudOptions.updateValueAndValidity();
      this.functionGroup.setValue('');
      this.functionGroup.updateValueAndValidity();
    }
  }

  getFunction() {
    const selectedFunctionGroup = this.cstmgmt.getSelectedFunction(this.costMgmtUserInfo.functionGroup);
    return (selectedFunctionGroup ? selectedFunctionGroup : '-');
  }

  loadCustomers() {
    this.costMgmtUserInfo.memberFirmCode = 'US';
    if (!this.cstmgmt.functionGroups || this.cstmgmt.functionGroups.length > 0) {
      this.functionGroups = this.cstmgmt.functionGroups;
      this.setSelectedFunctionGroup();
    } else {
      this.cstmgmt.getCustomers().subscribe((response: FunctionGroup[]) => {
        const functionGroups = _.filter(response, c => c.isStandardFss === true);
        this.cstmgmt.functionGroups = this.functionGroups = functionGroups;
        console.log(this.functionGroups);
        this.setSelectedFunctionGroup();
        this.ref.detectChanges();
      },
        error => {
          console.log(error);
          this.showErrorNotification('An error occurred while loading function groups.');
        }
      );
    }
  }

  fssChange() {
    this.costMgmtUserInfo.functionGroupId = this.functionGroup.value;
    this.messageService.sendMessage(this.costMgmtUserInfo);
    this.functionGroups.forEach(val => {
      if (val.customerId === +this.functionGroup.value) {
        this.costMgmtUserInfo.functionGroup = val.customerCode;
      }
    });
    console.log(this.costMgmtUserInfo.functionGroup);
  }

  cloudOptionsChange() {
    this.costMgmtUserInfo.csp = this.cloudOptions.value;
    if (this.cloudOptions.value === 'Aws') {
      this.costMgmtUserInfo.cloudProviderId = 9;
    }

    if (this.cloudOptions.value === 'Azure') {
      this.costMgmtUserInfo.cloudProviderId = 4;
    }

    if (this.cloudOptions.value === 'Gcp') {
      this.costMgmtUserInfo.cloudProviderId = 13;
    }
    this.messageService.sendMessage(this.costMgmtUserInfo);
  }

  getSelectedFunction(functionGroup) {
    const selectedFunctionGroup = _.find(this.functionGroups, item => item.id.toString() === functionGroup);
    return selectedFunctionGroup ? selectedFunctionGroup.description : null;
  }

  setSelectedFunctionGroup() {
    this.costMgmtUserInfo.selectedFunctionGroup = this.getFunction();
  }

  reset() {
    this.CreateCmcForm.reset();
  }

  showErrorNotification(message: string) {
    this.toastr.error(message);
  }

  showSuccessNotification(message: string) {
    this.toastr.success(message);
  }

}
