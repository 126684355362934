import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation-dialog-component',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.less']
})

export class ConfirmationDialogComponent implements OnInit {
  title = 'Confirm?';
  error = false;
  message = 'Are you sure?';
  warningMessage = '';
  showCancel = false;
  showCloseIcon = true;
  cloudViewId: string;
  cloudViewName: string;

  constructor(public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    console.log(data);
    this.title = this.data.title;
    this.message = this.data.message;
    this.warningMessage = this.data.warningMessage;
    this.error = this.data.error ? this.data.error : false;
    this.showCancel = this.data.showCancel ? this.data.showCancel : false;
    this.showCloseIcon = this.data.showCloseIcon ? this.data.showCloseIcon : true;
    this.cloudViewId = this.data.cloudViewId;
    this.cloudViewName = this.data.cloudViewName;
  }

  ngOnInit() {}

  closeDialog(message) {
    this.dialogRef.close(message);
  }
}

