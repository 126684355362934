<div>
    <div class="dialog">
      <div class="row">
        <div class="col-md-9">
            <h1> {{title}} </h1>
        </div>
        <div class="col-md-3 textAlignRight" *ngIf="showCloseIcon">
            <i class="fa fa-close close-icon" title="Close" (click)="closeDialog('X')"></i>
        </div>
      </div>
      <div class="horizontal-separtor divider"></div>
      <mat-dialog-content class="top-spacing-15">
        <div class="row top-spacing-15">
          <div class="col-md-12 confirmation-message" [ngClass]="error ? 'error-text' : ''">
            {{message}}
          </div>
          <div class="col-md-12 confirmation-message" *ngIf="cloudViewName && cloudViewId">
            <p><b>Cloudability View Name: &nbsp;</b><span>{{cloudViewName}}</span></p>
            <p><b>Cloudability View Id: &nbsp;</b><span>{{cloudViewId}}</span></p>
          </div>
        </div>
        <div class="row" *ngIf="warningMessage">
          <div class="col-md-12 warning-message" [innerHTML]="warningMessage">
          </div>
        </div>
        <div class="horizontal-separtor divider"></div>
        <div class="row top-spacing-15">
          <div class="col-md-12 textAlignRight">
            <button type="button" class="cancel-btn cursorPointer" *ngIf="showCancel" title="Click Cancel to Close" (click)="closeDialog('CANCEL')"><i class="fa fa-times" aria-hidden="true"></i> Cancel</button>
            <button type="button" class="delete-btn cursorPointer" (click)="closeDialog('OK')" title="Click OK to Close"><i class="fa fa-check" aria-hidden="true"></i> OK</button>
          </div>
        </div>
      </mat-dialog-content>
    </div>
  </div>
  