import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { CostMgmtUserInformation } from './costMgmtUserInformation';
import { UserShared } from './user.shared';

@Injectable({ providedIn: 'root' })
export class MessageService {
    assignUser: UserShared = {
        firstName: '',
        lastName: '',
        emailAddress: '',
        isSelected: false,
        userId: 0
    };
    costMgmtUserInformation: CostMgmtUserInformation = {
        pageTitle: '',
        costMgmtOptions: '',
        memberFirmCode: '',
        cloudProviderId: 0,
        assignUser: '',
        assignUsersList: [],
        assignUsers: this.assignUser,
        selectedFunctionGroup: '',
        csp: ''
    };
    private subject$ = new BehaviorSubject<CostMgmtUserInformation>(this.costMgmtUserInformation);
    private resetSubject$ = new BehaviorSubject<boolean>(false);

    sendMessage(costMgmtUserInformation: CostMgmtUserInformation) {
        console.log(costMgmtUserInformation);
        this.subject$.next(costMgmtUserInformation);
    }

    resetCostManagement(isReset: boolean) {
        this.resetSubject$.next(isReset);
    }

    onMessage(costMgmtOption: string, action: any): Subscription {
        return this.subject$.pipe(
            filter((costMgmtUserInformation: CostMgmtUserInformation) =>
                costMgmtUserInformation.costMgmtOptions === costMgmtOption),
            map((costMgmtUserInformation: CostMgmtUserInformation) => costMgmtUserInformation))
            .subscribe(action);
    }

    onResetCostManagement(action: any): Subscription {
        return this.resetSubject$.subscribe(action);
    }
}
