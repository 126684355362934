import { UserShared } from './user.shared';

export class CostMgmtUserInformation {
    pageTitle = 'Cloudability Users and Views';
    costMgmtOptions: string;
    memberFirmCode: string;
    assignUsers: UserShared;
    assignUsersList = [];
    assignUser: string;
    selectedFunctionGroup: string;
    functionGroup?: string;
    functionGroupId?: number;
    csp: string;
    cloudProviderId: number;
}
