<mat-card>
    <div class="row">
        <div class="col-sm-12">
            <form [formGroup]="CreateCmViewForm">
                <div class="row top-spacing-10">
                    <div class="col-sm-12">
                        <div class="form-group">
                            <label class="input-label">Cloudability View Name<span class="asterik">*</span>: &nbsp; </label>
                            <!-- <i class="fa fa-info-circle fa-help-icon-custom"
                                title=""></i> -->
                            <mat-form-field class="fromInputWidth" floatLabel="never">
                                <span matPrefix> {{costMgmtUserInfo.csp}}</span>
                                <span matPrefix> -US-</span>
                                <span matPrefix> {{costMgmtUserInfo.functionGroup}}&nbsp;</span>
                                <input matInput name="cloudabilityViewName" type="text" maxlength="20"
                                    class="form-control form-border-control" formControlName="cloudabilityViewName" />
                            </mat-form-field>
                            <!-- <p class="error-message" *ngIf="isCmsCodeAlreadyExists === true">
                                {{cmsCodeValidationError}}
                            </p> -->
                        </div>
                    </div>
                </div>
                <div class="selection-table-container">
                    <label class="input-label">Select available accounts to assign to your view </label>
                    <div class="row top-spacing-10">
                        <div class="col-sm-5 ">
                            <label class="ssp-content-sub-header-14">Available Accounts </label>
                            <mat-card class="pad-0">
                                <div class="example-container" id="style-4">
                                    <table mat-table [dataSource]="availableAccounts" class="">
                                        <!-- Checkbox Column -->
                                        <ng-container matColumnDef="select">
                                            <th mat-header-cell *matHeaderCellDef>
                                                <mat-checkbox (change)="$event ? masterToggleForAvailAcc() : null"
                                                    [checked]="selectionForAvailableAcc.hasValue() && isAllSelectedForAvailableAcc()"
                                                    [indeterminate]="selectionForAvailableAcc.hasValue() && !isAllSelectedForAvailableAcc()"
                                                    [aria-label]="checkboxLabelForAvailAcc()">
                                                </mat-checkbox>
                                            </th>
                                            <td mat-cell *matCellDef="let row">
                                                <mat-checkbox (click)="$event.stopPropagation()"
                                                    (change)="$event ? selectionForAvailableAcc.toggle(row) : null"
                                                    [checked]="selectionForAvailableAcc.isSelected(row)"
                                                    [aria-label]="checkboxLabelForAvailAcc(row)">
                                                </mat-checkbox>
                                            </td>
                                        </ng-container>

                                        <!-- Position Column -->
                                        <ng-container matColumnDef="cloudAccountId">
                                            <th mat-header-cell *matHeaderCellDef>Account ID </th>
                                            <td mat-cell title="{{element.cloudAccountId}}" *matCellDef="let element"> {{element.cloudAccountId}} </td>
                                        </ng-container>

                                        <!-- Name Column -->
                                        <ng-container matColumnDef="cloudAccountName">
                                            <th mat-header-cell *matHeaderCellDef> Account Name </th>
                                            <td mat-cell title="{{element.cloudAccountName}}" *matCellDef="let element"> {{element.cloudAccountName}} </td>
                                        </ng-container>

                                        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                                            (click)="selectionForAvailableAcc.toggle(row)">
                                        </tr>
                                    </table>
                                </div>
                                <mat-paginator [showFirstLastButtons]="true" [pageIndex]="pageIndex"
                                    [length]="totalRecords" [pageSize]="pageSize"
                                    (page)="onPageChange($event)">
                                </mat-paginator>
                            </mat-card>
                        </div>
                        <div class="col-sm-2 transfer-action-btns">
                            <div class="add-btn">
                                <button type="button" class="btn ssp-primary-btn"
                                    [disabled]="selectionForAvailableAcc.selected.length === 0 || 
                                    assignedAccounts.length >= 50"
                                    (click)="avilableToAssign()">
                                     Add <i class="fa fa-angle-double-right fa-lg" aria-hidden="true"></i>
                                </button>
                            </div>

                            <div class="remove-btn">
                                <button type="button" class="btn ssp-primary-btn"
                                    [disabled]="selectionForAssAcc.selected.length === 0"
                                    (click)="removeAssign()"> 
                                    <i class="fa fa-angle-double-left fa-lg" aria-hidden="true"></i>
                                    Remove
                                </button>
                            </div>

                        </div>
                        <div class="col-sm-5">
                            <label class="ssp-content-sub-header-14">Assigned Accounts </label>
                            <mat-card class="pad-0">                                
                                <div class="example-container scrollbar" id="style-4">
                                    <table mat-table [dataSource]="assignedAccounts" class="">
                                        <!-- Checkbox Column -->
                                        <ng-container matColumnDef="select">
                                            <th mat-header-cell *matHeaderCellDef>
                                                <mat-checkbox (change)="$event ? masterToggleForAssAcc() : null"
                                                    [checked]="selectionForAssAcc.hasValue() && isAllSelectedForAssAcc()"
                                                    [indeterminate]="selectionForAssAcc.hasValue() && !isAllSelectedForAssAcc()"
                                                    [aria-label]="checkboxLabelForAssAcc()">
                                                </mat-checkbox>
                                            </th>
                                            <td mat-cell *matCellDef="let row">
                                                <mat-checkbox (click)="$event.stopPropagation()"
                                                    (change)="$event ? selectionForAssAcc.toggle(row) : null"
                                                    [checked]="selectionForAssAcc.isSelected(row)"
                                                    [aria-label]="checkboxLabelForAssAcc(row)">
                                                </mat-checkbox>
                                            </td>
                                        </ng-container>

                                        <!-- Position Column -->
                                        <ng-container matColumnDef="cloudAccountId">
                                            <th mat-header-cell *matHeaderCellDef>Account ID </th>
                                            <td mat-cell title="{{element.cloudAccountId}}" *matCellDef="let element"> {{element.cloudAccountId}} </td>
                                        </ng-container>

                                        <!-- Name Column -->
                                        <ng-container matColumnDef="cloudAccountName">
                                            <th mat-header-cell *matHeaderCellDef> Account Name </th>
                                            <td mat-cell title="{{element.cloudAccountName}}" *matCellDef="let element"> {{element.cloudAccountName}} </td>
                                        </ng-container>
                                        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                                            (click)="selectionForAssAcc.toggle(row)">
                                        </tr>
                                    </table>
                                </div>
                            </mat-card>
                        </div>
                    </div>
                </div>
                <div class="row top-spacing-10">
                    <div class="col-md-12 col-lg-12 col-xs-12">
                        <div class="form-group">
                            <label class="input-label">Assign Users<span class="asterik">*</span></label>
                            <i class="fa fa-info-circle fa-help-icon-custom" title=""></i>
                            <br>
                            <mat-chip-list *ngIf="costMgmtUserInfo.assignUsersList">
                                <mat-chip *ngFor="let manager of costMgmtUserInfo.assignUsersList">
                                    {{manager}}&nbsp;<i class="fa fa-close close-icon" title="Remove"
                                        (click)="removeSelectedAssignUsers(manager)"></i>
                                </mat-chip>
                            </mat-chip-list>
                            <mat-form-field class="fromInputWidth">
                                <input type="text" matInput formControlName="assignUsers" class="mat-select-container"
                                    [matAutocomplete]="assignUsers" name="assignUsers">
                                <mat-autocomplete #assignUsers="matAutocomplete"
                                    (optionSelected)="assignUsersOptionSelected()" [displayWith]="displayOpmUserFn"
                                     autoActiveFirstOption>
                                    <mat-option [value]="" *ngIf="filteredOptionsAssignUsers.length === 0">No results
                                        found
                                    </mat-option>
                                    <mat-option *ngFor="let option of filteredOptionsAssignUsers" [value]="option">
                                        <div *ngIf="option">{{option.lastName}}, {{option.firstName}} -
                                            {{option.emailAddress}}</div>
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                            <br>
                        </div>
                    </div>
                </div>
                <div class="row top-spacing-10">
                    <div class="col-md-12 col-lg-12 col-xs-12">
                        <div class="action-buttons-container action-buttons-root">
                            <button type="button" id="update" [disabled]="CreateCmViewForm.invalid"
                                class="btn ssp-primary-btn float-right" (click)="save()">
                                <i class="fa fa-floppy-o" aria-hidden="true"></i> Save
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</mat-card>