import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, startWith, switchMap } from 'rxjs/operators';
import { Cstmgmt } from '../shared/costmgmt.service';
import { CostMgmtUserInformation } from '../shared/costMgmtUserInformation';
import * as _ from 'lodash';


@Component({
  selector: 'app-add-cost-management-user',
  templateUrl: './add-cost-management-user.component.html',
  styleUrls: ['./add-cost-management-user.component.less']
})
export class AddCostManagementUserComponent implements OnInit {
  @Input() costMgmtUserInfo: CostMgmtUserInformation;

  AddCmViewForm: FormGroup;
  cloudViewName: FormControl = new FormControl('', Validators.compose([Validators.required]));
  cloudViewNames: any[];
  assignUsers: FormControl = new FormControl('', Validators.compose([Validators.required]));
  filteredOptionsAssignUsers: any[];
  AssignedUsers: any[];
  isShowMinimumCmsManagerWarningMessage: boolean;
  isMaximumCmsManagerEventInitiated: boolean;
  isShowMaximumCmsManagerWarningMessage: boolean;

  constructor(private fb: FormBuilder, private cstmgmt: Cstmgmt) { }

  ngOnInit(): void {
    this.AddCmViewForm = this.fb.group({
      cloudViewName: this.cloudViewName,
      assignUsers: this.assignUsers
    });
    this.isShowMaximumCmsManagerWarningMessage = false;
    this.isMaximumCmsManagerEventInitiated = false;
    this.cloudViewNames = [];
    this.filteredOptionsAssignUsers = [];
    this.AssignedUsers = [];
    this.initiateAutoCompletes();
  }

  // Assign Users
  displayOpmUserFn(user) {
    if (!user) {
      return '';
    } else if (!user.lastName || user.lastName === '') {
      return user.firstName;
    } else {
      return user.lastName + ', ' + user.firstName;
    }
  }

  initiateAutoCompletes() {
    this.assignUsers.valueChanges.pipe(
      startWith(''),
      startWith(null as string),
      debounceTime(500),
      distinctUntilChanged(),
      switchMap(val => {
        return this._filterSearchOpmUsers(val);
      })
    ).subscribe(users => {
      this.filteredOptionsAssignUsers = users;
    });
  }

  _filterSearchOpmUsers(val: string): Observable<any[]> {
    if (typeof val === 'string') {
      val = val.trim();
    }
    // call the service which makes the http-request
    if (val && val.length > 3) {
      return this.cstmgmt.searchOpmUsers({
        searchText: val,
        // memberFirmCode: this.memberFirmCode
      });
    } else {
      return new Observable(ob => { ob.next(['']); });
    }
  }

  handleAssignUsersClosedEvent(event) {
    this.enableAssignUser();
    if (!(this.filteredOptionsAssignUsers && this.filteredOptionsAssignUsers.length > 0)) {
      return;
    }
    if (this.assignUsers && this.assignUsers.value
      && this.assignUsers.value.emailAddress && _.find(this.filteredOptionsAssignUsers,
        r => r.emailAddress === this.assignUsers.value.emailAddress)) {
      return;
    }
    const selectedOption = this.filteredOptionsAssignUsers[0];
    if (selectedOption && selectedOption.emailAddress) {
      this.assignUsers.setValue(selectedOption);
      this.assignUsersOptionSelected();
    }
  }

  assignUsersOptionSelected() {
    this.costMgmtUserInfo.assignUser = null;
    if (this.assignUsers.value && this.assignUsers.value.emailAddress) {
      this.costMgmtUserInfo.assignUser = this.assignUsers.value;
      this.isMaximumCmsManagerEventInitiated = false;
      if (this.costMgmtUserInfo.assignUsersList.length < 2) {
        this.setCmsManagerValueIfNotExists(this.costMgmtUserInfo.assignUsersList.length, this.assignUsers.value);
      } else {
        this.isMaximumCmsManagerEventInitiated = true;
      }
    }
  }

  enableAssignUser() {
    if (this.costMgmtUserInfo.assignUsersList.length === 0) {
      this.assignUsers.setValidators([Validators.required, Validators.minLength(4)]);
      this.assignUsers.updateValueAndValidity({ emitEvent: true });
      return true;
    } else {
      this.assignUsers.setValidators(null);
      this.assignUsers.updateValueAndValidity({ emitEvent: false });
      return false;
    }
  }

  removeSelectedAssignUsers(managerEmailAddress) {
    _.remove(this.costMgmtUserInfo.assignUsersList, b => b === managerEmailAddress);
    if (this.costMgmtUserInfo.assignUsersList.length <= 2) {
      this.isShowMaximumCmsManagerWarningMessage = false;
    }
    if (this.costMgmtUserInfo.assignUsersList.length === 0) {
      this.isShowMinimumCmsManagerWarningMessage = true;
    }
    this.enableAssignUser();
  }

  resetAssignUser() {
    this.costMgmtUserInfo.assignUser = null;
    this.assignUsers.setValue(null);
  }

  setCmsManagerValueIfNotExists(index: number, cmsManagerValue) {
    if (!_.some(this.costMgmtUserInfo.assignUsersList,
      b => b === cmsManagerValue.emailAddress)) {
        this.costMgmtUserInfo.assignUsersList[index] = cmsManagerValue.emailAddress;
    }
    if (this.costMgmtUserInfo.assignUsersList.length > 0) {
      this.isShowMinimumCmsManagerWarningMessage = false;
    }
  }
  setCmsManagerAndWarningMessage() {
    this.resetAssignUser();
    if (this.isMaximumCmsManagerEventInitiated) {
      this.isShowMaximumCmsManagerWarningMessage = true;
    }
  }

  cvnChange() {

  }

  save() {

  }

}
