<div class="container">
    <div class="row warning-wrapper" *ngIf="isShowAccessError===true">
        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
            <div class="alert alert-warning" role="alert">
                <i class="fa fa-exclamation-triangle warning-icon"></i>
                <span class="warning-desc">
                    You do not have access to use CMS configuration. Please contact your local IT support group for
                    assistance.
                </span>
            </div>
        </div>
    </div>
    <ng-container *ngIf="isShowAccessError===false">
        <div class="row cms-config-container">
            <div class="col-xs 12 col-sm-12">
                <h2 class="form-title">{{costMgmtUserInfo.pageTitle}}</h2>
            </div>
        </div>
        <mat-card>
            <div class="formBlk">
                <form [formGroup]="CreateCmcForm">
                    <div class="row top-spacing-10">
                        <div class="col-md-12 col-lg-12 col-xs-12">
                            <div class="form-group">
                                <mat-radio-group aria-label="Select an option" formControlName="costMgmtOptions"
                                    (change)="costMgmtOptionsChange()">
                                    <mat-radio-button class="main-radio-group" value="1">Create a new Cloudability view
                                    </mat-radio-button>
                                    <div class="form-group sub-radio" *ngIf="costMgmtOptions.value === '1'">
                                        <p class="form-title">Select a cloud platform:</p>
                                        <mat-radio-group formControlName="cloudOptions" (change)="cloudOptionsChange()">
                                            <mat-radio-button class="sub-radio-group" value="Aws">AWS</mat-radio-button>
                                            <mat-radio-button class="sub-radio-group" value="Azure">Azure</mat-radio-button>
                                            <mat-radio-button class="sub-radio-group" value="Gcp">GCP</mat-radio-button>
                                        </mat-radio-group>
                                    </div>
                                    <div class="col-md-6 col-lg-6 col-xs-6" *ngIf="costMgmtOptions.value === '1'">
                                        <div class="form-group">
                                            <label class="input-label">Function/Group<span class="asterik">*</span></label>
                                            <!-- [disabled]="summaryInformation.isManage || !summaryInformation.applicationInformation.memberFirmCode" -->
                                            <select class="form-control" formControlName="functionGroup" (change)="fssChange()">
                                            <option value="">--Select a Function/Group--</option>
                                            <option *ngFor="let functionGroup of functionGroups" [value]="functionGroup.customerId">
                                                {{functionGroup.name}}
                                            </option>
                                            </select>
                                        </div>
                                    </div>
                                    <mat-radio-button class="main-radio-group" value="2">Assign a user to existing view
                                    </mat-radio-button>
                                    <mat-radio-button class="main-radio-group" value="3">Modify view</mat-radio-button>
                                </mat-radio-group>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </mat-card>
        
        <div *ngIf="costMgmtOptions.value === '1' && cloudOptions.value && functionGroup.value">
            <app-create-cost-management-user-view></app-create-cost-management-user-view>
        </div>

        <div *ngIf="costMgmtOptions.value === '2' && !cloudOptions.value">
            <app-add-cost-management-user [costMgmtUserInfo]="costMgmtUserInfo"></app-add-cost-management-user>
        </div>

        <div *ngIf="costMgmtOptions.value === '3' && !cloudOptions.value">
            <app-update-cost-management-user-view [costMgmtUserInfo]="costMgmtUserInfo"></app-update-cost-management-user-view>
        </div>
    </ng-container>
</div>