import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Subject } from 'rxjs';
import { auth$ } from '@oc/auth';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent implements OnInit, OnDestroy {
  appVisible = false;
  private readonly destroying$ = new Subject<void>();

  constructor(private ref: ChangeDetectorRef) { }

  ngOnInit() {
    auth$.subscribe((val: { isAuthenticated: boolean; name: string, username: string }) => {
      this.appVisible = val.isAuthenticated;
      if (val.isAuthenticated === false) {
        this.appVisible = false;
      } else {
        console.log('showing cms manage!');
        this.appVisible = true;
      }
      console.log(this.appVisible);
      this.ref.detectChanges();
    });
  }

  ngOnDestroy(): void {
    this.destroying$.next(null);
    this.destroying$.complete();
  }
}
