import { SelectionModel } from '@angular/cdk/collections';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, startWith, switchMap } from 'rxjs/operators';
import { Cstmgmt } from '../shared/costmgmt.service';
import * as _ from 'lodash';
import { CostMgmtUserInformation } from '../shared/costMgmtUserInformation';
import { PageEvent } from '@angular/material/paginator';
import { ToastrService } from 'ngx-toastr';
import { HostingSearchParams } from '../shared/hostingSearchParams';
import { SearchCountResults } from '../shared/searchCountResults';
import { MessageService } from '../shared/message.service';
import { CloudabilityCreateViewRequest, CloudAccountDetail } from '../shared/cloudabilityCreateViewRequest';
import { CreateConstants } from '../shared/createConstant';
import { ConfirmationDialogComponent } from '../shared/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';



@Component({
  selector: 'app-create-cost-management-user-view',
  templateUrl: './create-cost-management-user-view.component.html',
  styleUrls: ['./create-cost-management-user-view.component.less']
})
export class CreateCostManagementUserViewComponent implements OnInit {

  costMgmtUserInfo: CostMgmtUserInformation;

  displayedColumns: string[] = ['select', 'cloudAccountId', 'cloudAccountName'];
  // availableAccounts = [];
  availableAccounts = new MatTableDataSource<any>();
  assignedAccounts = [];
  removeAssignedAccounts = [];
  selectionForAvailableAcc = new SelectionModel(true, []);
  selectionForAssAcc = new SelectionModel(true, []);
  CreateCmViewForm: FormGroup;
  cloudabilityViewName: FormControl = new FormControl('', Validators.compose([Validators.required]));
  assignUsers: FormControl = new FormControl('', Validators.compose([Validators.required]));
  filteredOptionsAssignUsers: any[];
  AssignedUsers: any[];
  isShowMinimumCmsManagerWarningMessage: boolean;
  isMaximumCmsManagerEventInitiated: boolean;
  isShowMaximumCmsManagerWarningMessage: boolean;
  pageIndex: number;
  pageSize: number;
  totalRecords: number;
  hostingSearchParams = new HostingSearchParams();
  searchCountResults = new SearchCountResults();
  isShowNoResults: boolean;

  constructor(private fb: FormBuilder, public dialog: MatDialog, private cstmgmt: Cstmgmt,
              private toastr: ToastrService, private messageService: MessageService) {
    this.messageService.onMessage('1', (costMgmtUserInfo: CostMgmtUserInformation) => {
      console.log(costMgmtUserInfo);
      this.costMgmtUserInfo = costMgmtUserInfo;
      this.init();
    });
    this.CreateCmViewForm = this.fb.group({
      cloudabilityViewName: this.cloudabilityViewName,
      assignUsers: this.assignUsers
    });
  }

  ngOnInit(): void {
  }

  init() {
    this.pageIndex = 0;
    this.pageSize = 50;
    this.totalRecords = 0;
    this.isShowNoResults = false;
    this.hostingSearchParams.memberFirmCode = 'US';
    this.hostingSearchParams.customerId = this.costMgmtUserInfo.functionGroupId;
    this.hostingSearchParams.cloudProviderId = this.costMgmtUserInfo.cloudProviderId;
    console.log(this.hostingSearchParams);
    this.isShowMaximumCmsManagerWarningMessage = false;
    this.isMaximumCmsManagerEventInitiated = false;
    this.filteredOptionsAssignUsers = [];
    this.AssignedUsers = [];
    this.searchCountResults = new SearchCountResults();
    this.initiateAutoCompletes();
    if (this.hostingSearchParams.cloudProviderId && this.hostingSearchParams.customerId) {
      this.resetValues();
      this.getTotalCountAndSearch();
    }
  }

  datatable(result) {
    //  this.displayTableData.push(result);
    result.sort((a, b) => (a.cloudAccountName > b.cloudAccountName) ? 1 : -1);
    this.availableAccounts.data = result;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelectedForAvailableAcc() {
    const numSelected = this.selectionForAvailableAcc.selected.length;
    const numRows = this.availableAccounts.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggleForAvailAcc() {
    this.isAllSelectedForAvailableAcc() ?
      this.selectionForAvailableAcc.clear() :
      this.availableAccounts.data.forEach(row => this.selectionForAvailableAcc.select(row));
    console.log(this.selectionForAvailableAcc.selected);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabelForAvailAcc(row?): string {
    if (!row) {
      return `${this.isAllSelectedForAvailableAcc() ? 'select' : 'deselect'} all`;
    }
    return `${this.selectionForAvailableAcc.isSelected(row) ? 'deselect' : 'select'} row ${row.cloudAccountId + 1}`;
  }

  isAllSelectedForAssAcc() {
    const numSelected = this.selectionForAssAcc.selected.length;
    const numRows = this.assignedAccounts.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggleForAssAcc() {
    this.isAllSelectedForAssAcc() ?
      this.selectionForAssAcc.clear() :
      this.assignedAccounts.forEach(row => this.selectionForAssAcc.select(row));
    console.log(this.selectionForAssAcc.selected);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabelForAssAcc(row?): string {
    if (!row) {
      return `${this.isAllSelectedForAssAcc() ? 'select' : 'deselect'} all`;
    }
    return `${this.selectionForAssAcc.isSelected(row) ? 'deselect' : 'select'} row ${row.cloudAccountId + 1}`;
  }

  avilableToAssign() {
    if (this.selectionForAvailableAcc.selected.length < 51) {
      this.assignedAccounts = this.selectionForAvailableAcc.selected;
      this.availableAccounts.data = this.availableAccounts.data.filter(val => !this.assignedAccounts.includes(val));
      if (this.selectionForAvailableAcc.selected.length === 50) {
        this.showSuccessNotification('You have successfully added 50 accounts.');
      }
    } else {
      const num = this.selectionForAvailableAcc.selected.length - 50;
      this.showErrorNotification('You have reached the limit for assigning the accounts. Deselect ' + num +
        ' accounts to proceed.');
    }
  }

  removeAssign() {
    let removeData = [];
    removeData = this.availableAccounts.data;
    this.removeAssignedAccounts = this.selectionForAssAcc.selected;
    this.assignedAccounts = this.assignedAccounts.filter(val => !this.removeAssignedAccounts.includes(val));
    this.removeAssignedAccounts.forEach(item => {
      removeData.push(item);
    });
    this.datatable(removeData);
    this.removeAssignedAccounts = [];
    this.selectionForAssAcc.clear();
  }

  onPageChange(e: PageEvent) {
    this.setAvailAccParams(e.pageIndex + 1);
    this.doSearch();
  }

  setAvailAccParams(pageIndex) {
    this.hostingSearchParams.page = pageIndex;
    this.isShowNoResults = false;
  }

  getTotalCountAndSearch() {
    this.hostingSearchParams.pageSize = 50;
    this.hostingSearchParams.page = 1;
    this.cstmgmt.hostingSearchParams = this.hostingSearchParams;
    console.log(this.hostingSearchParams);
    this.cstmgmt.getAcc(this.hostingSearchParams).subscribe((response: any) => {
      this.searchCountResults = response;
      if (this.searchCountResults && this.searchCountResults.totalRecords > 0) {
        this.totalRecords = this.searchCountResults.totalRecords;
        this.availableAccounts.data = null;
        this.availableAccounts.data = response.cloudAccounts;
        this.isShowNoResults = this.availableAccounts.data && this.availableAccounts.data.length === 0;
      } else {
        this.isShowNoResults = true;
      }
    },
      error => {
        this.showErrorNotification('An error occurred while loading total report counts.');
      }
    );
  }

  doSearch() {
    this.hostingSearchParams.pageSize = 50;
    this.cstmgmt.hostingSearchParams = this.hostingSearchParams;
    this.cstmgmt.getAcc(this.hostingSearchParams).subscribe((response) => {
      this.availableAccounts.data = null;
      this.availableAccounts.data = response.cloudAccounts;
      this.isShowNoResults = this.availableAccounts.data && this.availableAccounts.data.length === 0;
    },
      error => {
        this.isShowNoResults = this.availableAccounts.data && this.availableAccounts.data.length === 0;
        this.showErrorNotification('An error occurred while loading report results.');
      }
    );
  }


  // Assign Users
  displayOpmUserFn(user) {
    if (!user) {
      return '';
    } else if (!user.lastName || user.lastName === '') {
      return user.firstName;
    } else {
      return user.lastName + ', ' + user.firstName;
    }
  }

  initiateAutoCompletes() {
    this.assignUsers.valueChanges.pipe(
      startWith(''),
      startWith(null as string),
      debounceTime(500),
      distinctUntilChanged(),
      switchMap(val => {
        return this._filterSearchOpmUsers(val);
      })
    ).subscribe(users => {
      this.filteredOptionsAssignUsers = users;
    });
  }

  _filterSearchOpmUsers(val: string): Observable<any[]> {
    if (typeof val === 'string') {
      val = val.trim();
    }
    // call the service which makes the http-request
    if (val && val.length > 3) {
      return this.cstmgmt.searchOpmUsers({
        searchText: val,
        memberFirmCode: 'US'
      });
    } else {
      return new Observable(ob => { ob.next(['']); });
    }
  }

  handleAssignUsersClosedEvent(event) {
    this.enableAssignUser();
    if (!(this.filteredOptionsAssignUsers && this.filteredOptionsAssignUsers.length > 0)) {
      return;
    }
    if (this.assignUsers && this.assignUsers.value
      && this.assignUsers.value.emailAddress && _.find(this.filteredOptionsAssignUsers,
        r => r.emailAddress === this.assignUsers.value.emailAddress)) {
      this.assignUsersOptionSelected();
      return;
    }
    const selectedOption = this.filteredOptionsAssignUsers[0];
    if (selectedOption && selectedOption.emailAddress) {
      this.assignUsers.setValue(selectedOption);
      this.assignUsersOptionSelected();
    }
  }

  assignUsersOptionSelected() {
    this.costMgmtUserInfo.assignUser = null;
    if (this.assignUsers.value && this.assignUsers.value.emailAddress) {
      this.costMgmtUserInfo.assignUser = this.assignUsers.value;
      this.setUsersrValueIfNotExists(this.costMgmtUserInfo.assignUsersList.length, this.assignUsers.value);
    }
  }

  enableAssignUser() {
    if (this.costMgmtUserInfo.assignUsersList.length === 0) {
      this.assignUsers.setValidators([Validators.required, Validators.minLength(4)]);
      this.assignUsers.updateValueAndValidity({ emitEvent: true });
      return true;
    } else {
      this.assignUsers.setValidators(null);
      this.assignUsers.updateValueAndValidity({ emitEvent: false });
      return false;
    }
  }

  removeSelectedAssignUsers(managerEmailAddress) {
    _.remove(this.costMgmtUserInfo.assignUsersList, b => b === managerEmailAddress);
    this.enableAssignUser();
  }

  resetAssignUser() {
    this.costMgmtUserInfo.assignUser = null;
    this.assignUsers.setValue(null);
  }

  setUsersrValueIfNotExists(index: number, assignUserValue) {
    if (!_.some(this.costMgmtUserInfo.assignUsersList,
      b => b === assignUserValue.emailAddress)) {
      this.costMgmtUserInfo.assignUsersList[index] = assignUserValue.emailAddress;
      this.assignUsers.setValue('');
      this.assignUsers.setValidators(null);
      this.assignUsers.updateValueAndValidity();
    }
  }
  setUserAndWarningMessage() {
    this.resetAssignUser();
    if (this.isMaximumCmsManagerEventInitiated) {
      this.isShowMaximumCmsManagerWarningMessage = true;
    }
  }

  save() {
    const viewRequest = new CloudabilityCreateViewRequest();
    const cloudArray = [];
    let cloudObject = new CloudAccountDetail();
    viewRequest.viewName = this.cloudabilityViewName.value;

    viewRequest.memberFirmCode = 'US';
    viewRequest.cloudProviderId = this.costMgmtUserInfo.cloudProviderId;
    viewRequest.users = this.costMgmtUserInfo.assignUsersList;
    viewRequest.customerId = +this.costMgmtUserInfo.functionGroupId;
    this.assignedAccounts.forEach(val => {
      cloudObject = {
        cloudAccountId: val.cloudAccountId,
        bundleId: val.bundleId,
        bundleEnvironmentId: val.bundleEnvironmentId
      };
      cloudArray.push(cloudObject);
    });
    viewRequest.cloudAccounts = cloudArray;

    this.cstmgmt.createView(viewRequest).subscribe( val => {
      console.log(val);
      this.resetValues();
      this.displaySuccessNotificationDialog(val);
    },
    error => {
      console.log('From activity search: ' + error);
      this.showErrorNotification('An error occurred while creating the view.');
    });
  }

  resetValues() {
    this.cloudabilityViewName.setValue(null);
    this.assignedAccounts = [];
    //  this.doSearch();
    this.costMgmtUserInfo.assignUsersList = [];
    this.assignUsers.setValue(null);
    this.selectionForAssAcc.clear();
    this.selectionForAvailableAcc.clear();
  }

  showErrorNotification(message: string) {
    this.toastr.error(message);
  }

  showSuccessNotification(message: string) {
    this.toastr.success(message);
  }

  displaySuccessNotificationDialog(response: any) {
    const title = this.costMgmtUserInfo.pageTitle;

    const msg = response.warningMessage ? CreateConstants.createSaveHold : CreateConstants.createSaveSuccess;

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title,
        message: msg,
        warningMessage: response.warningMessage,
        cloudViewId: response.cloudabilityViewId,
        cloudViewName: response.cloudabilityViewName
      },
      width: '450px'
    });
    dialogRef.afterClosed().subscribe(result => {
      this.messageService.resetCostManagement(true);
    });
  }
}
