import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { APP_BASE_HREF } from '@angular/common';
import { EmptyRouteComponent } from './empty-route/empty-route.component';
import { CostManagementHomeComponent } from './cost-management-home/cost-management-home.component';
// import { AuthenticationGuard } from 'microsoft-adal-angular6';

const routes: Routes = [
  { path: 'cloudabilityUsers', component: CostManagementHomeComponent },
  { path: '**', component: EmptyRouteComponent }
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      routes,
      {
        enableTracing: false
      } // <-- debugging purposes only
    )
  ],
  exports: [RouterModule],
  providers: [{ provide: APP_BASE_HREF, useValue: '/' }]
})
export class AppRoutes { }
