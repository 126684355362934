import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Cstmgmt } from '../shared/costmgmt.service';
import { CostMgmtUserInformation } from '../shared/costMgmtUserInformation';

@Component({
  selector: 'app-update-cost-management-user-view',
  templateUrl: './update-cost-management-user-view.component.html',
  styleUrls: ['./update-cost-management-user-view.component.less']
})
export class UpdateCostManagementUserViewComponent implements OnInit {
  @Input() costMgmtUserInfo: CostMgmtUserInformation;

  constructor(private fb: FormBuilder, private cstmgmt: Cstmgmt) { }

  ngOnInit(): void {
  }

}
