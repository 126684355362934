import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpHeaders
} from '@angular/common/http';
import { throwError as observableThrowError, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { auth$ } from '@oc/auth';
// import { MsAdalAngular6Service } from 'microsoft-adal-angular6';


@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    constructor(private router: Router) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        console.log('accessToken');
        console.log(auth$._value.accessToken);
        request = request.clone({
          headers: request.headers.set('Cache-Control', 'no-cache')
            .set('Pragma', 'no-cache')
            .set('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT')
            .set('If-Modified-Since', '0'),
          setHeaders: {
            Authorization: `Bearer ${auth$._value.accessToken}`
          }
        });

        return next.handle(request).pipe(catchError((err) => {
            if (err.status === 401) {
                this.router.navigate(['error/notauthorised']);
            }
            if (err.status === 403) {
                this.router.navigate(['error/403']);
            }
            return observableThrowError(err);
        }));
    }
}
