import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { AngMaterialModule } from './shared/ang.material.module';
import { ToastrModule } from 'ngx-toastr';
import { NgProgressHttpModule } from '@ngx-progressbar/http';
import { NgProgressModule } from '@ngx-progressbar/core';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutes } from './app.routing';
import { CostManagementHomeComponent } from './cost-management-home/cost-management-home.component';
import { CreateCostManagementUserViewComponent } from './create-cost-management-user-view/create-cost-management-user-view.component';
import { AddCostManagementUserComponent } from './add-cost-management-user/add-cost-management-user.component';
import { UpdateCostManagementUserViewComponent } from './update-cost-management-user-view/update-cost-management-user-view.component';

import { ConfirmationDialogComponent } from './shared/confirmation-dialog/confirmation-dialog.component';
import { EmptyRouteComponent } from './empty-route/empty-route.component';
import { TokenInterceptor } from './shared/token.interceptor';



@NgModule({
  declarations: [
    AppComponent,
    CostManagementHomeComponent,
    CreateCostManagementUserViewComponent,
    AddCostManagementUserComponent,
    UpdateCostManagementUserViewComponent,
    ConfirmationDialogComponent,
    EmptyRouteComponent
  ],
  imports: [
    BrowserModule,
    AppRoutes,
    ReactiveFormsModule,
    RouterModule,
    HttpClientModule,
    NgbModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    AngMaterialModule,
    NgProgressModule,
    NgProgressHttpModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
